import { Component, OnInit, Renderer2 } from '@angular/core';
import { ModalController, NavController, Platform, isPlatform } from '@ionic/angular';
import { MessagingService } from 'src/providers/services/push.messaging.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { Router, NavigationStart } from '@angular/router';
import AOS from 'aos';
import { delay } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { NotificationService } from './shared/services/notification/notification.service';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { ChatComponent } from './content/components/chat/chat.component';
import { AuthService } from './core/services/auth.service';
import { AuthGuard } from './core/services/auth-guard.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  message;
  tokenByPassUrl = [
    '/login',
    '/login/mobile-login',
    '/signup',
    '/auth/signup',
    '/auth/forgotPasswordComplete',
    '/auth/activate',
    '/forgot-password',
    '/tabs/contents/niwi-faqs',
    '/tabs/contents/contact-us',
    '/tabs/diet/diet-programmes',
    '/tabs/shop/package',
    '/tabs/shop/special-packages',
    '/tabs/shop/premium-packages',
    '/tabs/shop/my-cart',
    '/tabs/shop/my-checkout',
    '/tabs/shop/discount-coupon',
    '/forms',
    '/guideline'
    // '/tabs/my-account/my-orders-page',
  ];
  onLogin: boolean;
  isSafe: boolean;

  isDragging = false;
  initialX: number;
  initialY: number;
  currentX: number = 0;
  currentY: number = 0;

  constructor(
    public platform: Platform,
    private messagingService: MessagingService,
    private jwtService: JwtService,
    private router: Router,
    private notificationService: NotificationService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private renderer: Renderer2,
    private authGuardService: AuthGuard
  ) {
    const url = window.location.href;    
    this.authGuardService.changeUrl(url.split(environment.app.app_url)[1]);
    
    this.isSafe = (this.platform.is('ios') || this.platform.is('iphone'));
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.checkForValidToken(val.url);
      }
    });
    this.jwtService.onToken.pipe(delay(1000)).subscribe((res) => {
      if (res === 'tokenSet') {
        this.onLogin = true;
      } else if (res === 'tokenDeleted') {
        this.onLogin = false;
      }
    });
    this.jwtService.getToken().then((res) => {
      if (res) {
        this.onLogin = true;
      } else {
        this.onLogin = false;
      }
    });
    AOS.init();
  }

  ngOnInit() {
    if(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios'){
      this.lockScreenOrientation();
    }
    // const userId = 'user001';
    // this.messagingService.requestPermission(userId);
    // this.messagingService.receiveMessage();
    // this.message = this.messagingService.currentMessage;

    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.registerPushNotification();
      }

      if (this.platform.is('android') || this.platform.is('ios')) {
        // console.log('I am on mobile');
      } else {
        // console.log('I am on desktop');
      }
    });
  }

  async checkForValidToken(url) {
    const token = await this.jwtService.getToken();
    if (token) {
      var decoded: any = jwt_decode(token);
      if (moment.unix(decoded.exp).isBefore(moment())) {
        this.jwtService.destroyToken().then((res) => {
          this.router.navigate(['/login']);
        });
      }
    } else {
      // console.log('redirect from app component');
      if (!this.tokenByPassUrl.includes(url) && !this.matchUrl(url)) {
        this.router.navigate(['/login']);
      }
    }
  }

  matchUrl(url: string): boolean {
    let byPass = false;
    this.tokenByPassUrl.forEach((byPassUrl) => {
      if (!byPass) {
        byPass = url.indexOf(byPassUrl) > -1;
      }
    });
    return byPass;
  }

  registerPushNotification() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    // PushNotifications.requestPermissions().then(result => {
    //   if (result.receive === 'granted') {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // Show some error
    //   }
    // });
    // // On success, we should be able to receive notifications
    // PushNotifications.addListener('registration',
    //   (token: Token) => {
    //     alert('Push registration success, token: ' + token.value);
    //   }
    // );
    // // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError',
    //   (error: any) => {
    //     alert('Error on registration: ' + JSON.stringify(error));
    //   }
    // );
    // // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener('pushNotificationReceived',
    //   (notification: PushNotificationSchema) => {
    //     alert('Push received: ' + JSON.stringify(notification));
    //   }
    // );
    // // Method called when tapping on a notification
    // PushNotifications.addListener('pushNotificationActionPerformed',
    //   (notification: ActionPerformed) => {
    //     alert('Push action performed: ' + JSON.stringify(notification));
    //   }
    // );
  }

  async lockScreenOrientation() {
    try {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    } catch (error) {
      console.error('Error locking screen orientation:', error);
    }
  }

  onDragStart(event: MouseEvent | TouchEvent) {
    this.isDragging = true;
    if (event instanceof MouseEvent) {
      this.initialX = event.clientX - this.currentX;
      this.initialY = event.clientY - this.currentY;
    } else if (event instanceof TouchEvent) {
      this.initialX = event.touches[0].clientX - this.currentX;
      this.initialY = event.touches[0].clientY - this.currentY;
    }
  }

  onDrag(event: MouseEvent | TouchEvent) {
    if (this.isDragging) {
      event.preventDefault();
      if (event instanceof MouseEvent) {
        this.currentX = event.clientX - this.initialX;
        this.currentY = event.clientY - this.initialY;
      } else if (event instanceof TouchEvent) {
        this.currentX = event.touches[0].clientX - this.initialX;
        this.currentY = event.touches[0].clientY - this.initialY;
      }
      this.setTranslate(this.currentX, this.currentY);
    }
  }

  onDragEnd(event: MouseEvent | TouchEvent) {
    this.isDragging = false;
  }

  setTranslate(xPos: number, yPos: number) {
    const fabElement = document.querySelector('.chat_floating_button') as HTMLElement;
    if (fabElement) {
      this.renderer.setStyle(fabElement, 'transform', `translate3d(${xPos}px, ${yPos}px, 0)`);
    }
  }

  async openChatModal() {
    const modal = await this.modalController.create({
      component: ChatComponent
    });
    return await modal.present();
  }
}
