import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ChatService } from '../../services/chat/chat.service';
import { Observable, Subscription } from 'rxjs';
import { DocumentData } from '@angular/fire/firestore';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/user/services/user.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewChecked {
  messages = [];
  newMessage = '';
  chatMessages = [];
  selectedFile: File | null = null;
  loadMessages$: Observable<DocumentData[]>;
  counsellorDeviceToken = [];
  loadingMessages: { [key: string]: boolean } = {};
  loadingImages: { [key: string]: boolean } = {};
  private messagesSubscription: Subscription;
  clientDetails = null;
  isIos; boolean = false;
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;

  constructor(
    private modalController: ModalController,
    public chatService: ChatService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private userService: UserService,
    public platform: Platform
  ) {
    this.isIos = (this.platform.is('ios') || this.platform.is('iphone'));
  }

  ngOnInit(): void {
    // Initialize messages$ with the observable returned by loadMessages()
    this.loadMessages$ = this.chatService.loadMessages();

    // Subscribe to messages$ to get real-time updates
    this.messagesSubscription = this.loadMessages$.subscribe({
      next: (messages) => {
        // Handle new messages
        this.messages = messages;
        this.chatMessagesBYDate();
        this.chatService.updateUnseenMessages().subscribe();
      },
      error: (error) => {
        // Handle errors
        console.error('Error loading messages:', error);
      }
    });

    this.authService.clientDetailsByToken$.subscribe((res: any) => {
      this.clientDetails = res;
    });

    this.userService.getCounsellorDeviceToken().subscribe((res: any) => {
        this.counsellorDeviceToken = res.data;
        console.log('Counsellor Device Token:', this.counsellorDeviceToken);
      },
      (error) => {
          console.error('Error fetching counsellor device token:', error);
      }
    );
    this.scrollToBottom(); // Scroll to bottom on initialization
  }

  ngAfterViewChecked() {
    // Scroll to bottom when new messages arrive
    this.scrollToBottom();
  }

  scrollToBottom() {
    // setTimeout(() => {
    const container = this.messagesContainer.nativeElement;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
    // }, 0);
  }

  chatMessagesBYDate() {
    const groupedMessages = [];

    this.messages.forEach(message => {
      const dateKey = new Date(message.timestamp).toISOString().split('T')[0];
      const formattedDate = moment(dateKey).format('DD-MM-YYYY');

      // Check if the date already exists in the groupedMessages array
      let dateGroup = groupedMessages.find(group => group.date === formattedDate);
      if (!dateGroup) {
          dateGroup = { date: formattedDate, messages: [] };
          groupedMessages.push(dateGroup);
      }
      dateGroup.messages.push(message);
    });

    // Sort the array of grouped messages by date
    groupedMessages.sort((a, b) => {
      const dateA = moment(a.date, 'DD-MM-YYYY').toDate().getTime();
      const dateB = moment(b.date, 'DD-MM-YYYY').toDate().getTime();
      return dateA - dateB;
    });

    this.chatMessages = groupedMessages;
}

  ngOnDestroy(): void {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  sendMessage() {
    const dateTimestamp = new Date().toISOString();
    const formattedDate = moment(dateTimestamp).format('DD-MM-YYYY');

    if (this.newMessage.trim()) {
      let data = {
        "collection_name": environment.firestoreCollection,
        "client_id": Number(this.clientDetails.client_id),
        "counsellor_id": Number(this.clientDetails.counsellor_id),
        "image_url": '',
        "message": this.newMessage,
        "sender_id": Number(this.clientDetails.client_id),
        "sender_name": this.clientDetails.client_name,
        "timestamp": dateTimestamp,
        "has_seen": false,
        "org_id": Number(this.clientDetails.org_id)
      }

      const existingGroupIndex = this.chatMessages.findIndex(group => group.date === formattedDate);
      if (existingGroupIndex !== -1) {
        // If it exists, push the new message into the existing group's messages
        this.chatMessages[existingGroupIndex].messages.push(data);
      } else {
        // If it doesn't exist, create a new group
        this.chatMessages.push({ date: formattedDate, messages: [data] });
      }

      this.loadingMessages[dateTimestamp] = true;

      this.chatService.sendMessageWithWebsocket(data).subscribe(
        (response) => {
          console.log('Message sent successfully:', response);
          this.loadingMessages[dateTimestamp] = false;
        },
        (error) => {
          console.error('Error sending message:', error);
          this.loadingMessages[dateTimestamp] = false;
        }
      );
      this.sendNotification(this.clientDetails.client_name,this.newMessage);
      this.newMessage = '';
    }
  }

  uploadImage(event: any) {
    const imgFile: File = event.target.files[0];
    if (!imgFile) {
      console.log(imgFile);
      return;
    }

    // Extract the extension from the MIME type
    const extension = imgFile.type.split('/')[1].toLowerCase();
    const allowedTypes = ["jpg", "jpeg", "png"];
    // Check if the extension exists in allowedTypes
    if (!allowedTypes.includes(extension)) {
      this.notificationService.presentToast(
            'Sorry, only JPG, JPEG & PNG files are allowed.',
            '',
            'danger'
          );
      return;
    }

    const timestamp = new Date().toISOString();
    const formattedDate = moment(timestamp).format('DD-MM-YYYY');
    const messageData = {
      "collection_name": environment.firestoreCollection,
      "client_id": Number(this.clientDetails.client_id),
      "counsellor_id": Number(this.clientDetails.counsellor_id),
      "sender_id": Number(this.clientDetails.client_id),
      "sender_name": this.clientDetails.client_name,
      "message": '',
      "image_url": '',
      "timestamp": timestamp,
      "has_seen": false,
      "org_id": Number(this.clientDetails.org_id)
    };

    const existingGroupIndex = this.chatMessages.findIndex(group => group.date === formattedDate);
    if (existingGroupIndex !== -1) {
      // If it exists, push the new message into the existing group's messages
      this.chatMessages[existingGroupIndex].messages.push(messageData);
    } else {
      // If it doesn't exist, create a new group
      this.chatMessages.push({ date: formattedDate, messages: [messageData] });
    }

    this.loadingImages[timestamp] = true;

    this.chatService.saveImageMessage(imgFile, messageData).then(() => {
      this.loadingImages[timestamp] = false;
    }).catch(() => {
      this.loadingImages[timestamp] = false;
    });
  }

  formatFullTime(date: Date): string {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
  }

  formatDisplayTime(dateStr: string): string {
    const date = new Date(dateStr);
    return this.formatFullTime(date);
  }

  closeChat() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  sendNotification(title, message) {
    let reqObj = {
      'title': title,
      'message': message,
      'device_token': this.counsellorDeviceToken
    }
    this.chatService.sendChatNotification(reqObj).subscribe((res) => {
      console.log(res);
    });
  }
}
