import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtService } from './jwt.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class AuthGuard implements CanActivate {
  public urlSource = new BehaviorSubject<string>('');
  requestUrl = this.urlSource.asObservable();
  constructor(
    private router: Router,
    private jwtService: JwtService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean>{    

    let token = await this.jwtService.getToken();
    
    if(token !== "" && token !== undefined && token !== null){
        return true;
      }else{
        console.log('Auth Guard');
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
      }
  }

  changeUrl(url: string){
    this.urlSource.next(url);
}

}
